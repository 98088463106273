import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import HomePageTemplate from "../components/HomePageTemplate";
import Layout from "../components/Layout";
import config from "../../config";
import SE0 from "../components/SEO";
import logo from "../assets/img/idealops.png";



const HomePage = ({ data }) => {
  return (
    <Layout>
      <SE0
        title={config.title}
        meta_title={config.siteTitle}
        meta_desc={config.siteDescription}
        contentType="website"
        cover = {logo}
        directory = "/"
        slug =""
        

      />
    
      <HomePageTemplate
        title={config.title}
        meta_title={config.siteTitle}
        meta_description={config.siteDescription}
        heading={config.servicesHeader}
        offerings={data.allContentfulServices}
        hero_img_url={data.allContentfulAsset.edges[0].node.file.url}
      />
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default HomePage;

export const pageQuery = graphql`
query homePageQueryReal {
  allContentfulServices(sort: {fields: rank, order: ASC}) {
    edges {
      node {
        images {
          file {
            url
          }
          title
        }
        childContentfulServicesContentRichTextNode {
          json
          id
        }
        gradientType
        columnDirection
        rank
        id
      }
    }
  }
  allContentfulAsset(filter: {file: {fileName: {eq: "hero-image-idealops.jpg"}}}) {
    edges {
      node {
        file {
          fileName
          url
        }
      }
    }
  }

}
`;
