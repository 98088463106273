import React from "react";
import Helmet from "react-helmet";
import { Link, graphql, StaticQuery, useStaticQuery } from "gatsby";
import Offerings from "../Offerings";
//import Testimonials from "../Testimonials";
import PropTypes from "prop-types";
import config from "../../../config";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Fade from "react-reveal/Fade";

const HomePageTemplate = ({
  title,
  heading,
  description,
  offerings,
  meta_title,
  meta_description,
  testimonials,
  hero_img_url,
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name="description" content={meta_description} />
    </Helmet>
    <section>
      <h1 className="title marketing trueHeader">Salesforce. Marketo. <span className="orangeText">Consulting. Managed Services.</span> </h1>
    </section>
    <section className="hero is-primary is-bold is-medium herover parallax">


      <div className="columns caption">
        <div className="column is-10 is-offset-1">
          <div className="section"></div>
        </div>
      </div>
    </section>
    <div className="offerGradient">
    {offerings.edges.map((item,index) => (
      <div key={index}>
        <section key={index} className={item.node.gradientType}>
          <div key={index} className="container">
            <div className="section" >
              <div className="columns">
                <div className="column is-12">
                  <div className="content">
                    <div style={{flexDirection:item.node.columnDirection}} className="columns is-multiline">
                      <div
                        className="column is-4"
                        style={{ borderRadius: "5px" }}
                      >
                        <section className="section">
                          <Fade effect="fadeInUp">
                            <p className="has-text-centered">
                              <img
                                className="floating"
                                alt={item.node.images[0].title}
                                src={item.node.images[0].file.url}
                              />
                            </p>
                          </Fade>
                        </section>
                      </div>
                      <div
                        className="column is-8"
                        style={{ borderRadius: "5px" }}
                      >
                        <section className="section">
                          <Fade effect="fadeInUp">
                            {documentToReactComponents(
                              item.node
                                .childContentfulServicesContentRichTextNode.json
                            )}
                          </Fade>
                          <Link className="button is-primary is-outlined" to="/services/">
                  Learn More >>
                </Link>
                        </section>
                      </div>
                    </div>
                    {/*<div>
                 <h3 className="has-text-weight-semibold is-size-2">
                    {heading}
                  </h3>
                  <p>{description}</p>
               </div> 
                <Offerings gridItems={offerings.edges} />
                  <h2 className="has-text-weight-semibold is-size-2">
                  Testimonials
                </h2>
              {/*<Testimonials testimonials={testimonials} />*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    ))}
    </div>
  </div>
);

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,
  hero_img_url: PropTypes.string,
};

export default HomePageTemplate;
